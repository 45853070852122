body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

.navbar {
  background-color: #333;
  color: white;
  padding: 1em;
  text-align: center;
}

.navbar ul {
  list-style-type: none;
  padding: 0;
}

.navbar ul li {
  display: inline;
  margin: 0 10px;
}

.navbar ul li a {
  color: white;
  text-decoration: none;
}

.section {
  padding: 50px;
  text-align: center;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
}

.main-image {
  width: 80%;
  height: auto;
}

.product-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.product {
  max-width: 30%;
  margin: 20px 0;
}

.founder-photo {
  width: 150px;
  border-radius: 50%;
  margin-top: 20px;
}

h1, h2, h3 {
  font-family: 'Roboto', sans-serif;
  color: #333;
}
